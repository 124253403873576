import React, { useEffect, useState } from "react";

// import './ack.css'

const ACK = ({ }) => {

return (

    
<div className="c4 doc-content">
<ol>
  <li>Sickening Shop is Free for artists, HOWEVER in order to keep it free we have to limit some features and functionality to keep our doors open

  <ol style={{paddingLeft:20}}>
      <li style={{listStyleType:'lower-alpha'}}>As long as you sell 4 items a month, Sickening Shop will be free. If you cannot meet that requirement there is a $4.99USD monthly  fee to keep your items online in the store. </li>
      <li style={{listStyleType:'lower-alpha'}} >Due to high demand we can only offer up to 4 products with 3 color variations for free. If you’d like additional variations or products, we are happy to add them for a low one-time fee, this helps us compensate our people for the extra time it takes to get your awesome merch live. Extra colors are $15USD and extra products are $35USD</li>
    </ol>
  </li>
  <li>As you get more of your merch out there, you will earn the ability to add more merch to your collection free of charge.
    <ol style={{paddingLeft:20}}>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 150 pieces sold: up to 5 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 300 pieces sold: up to 6 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 500 pieces sold: up to 7 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 750 pieces sold: up to 8 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 1000 pieces sold: up to 9 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 1500 pieces sold: up to 12 free listed products</li>
      <li style={{listStyleType:'lower-alpha'}}>Greater than 2000 pieces sold: up to 20 free listed products</li>
    </ol>
  </li>
  <li>Payments: If a payment method expires, is canceled, deleted, or is bounced, you  will have 30 days to remedy the issue, after that the merch will be removed from the SS site and not be able to be reinstated for a minimum of 90 days. </li>
</ol>
</div>
)



}

export default ACK;
