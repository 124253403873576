import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import Footer from "./Global/Footer";
import Header from "./Global/Header";
import logoLight from "../Assets/images/logo.png";
import logoDark from "../Assets/images/logo.png";
import { useSelector, useDispatch } from 'react-redux'
import { addForm, newPage } from "../reducer/formReducer"
import Axios from 'axios'
import { endPoint } from "../api";
import '../index.css';
import { useLocation } from 'react-router-dom';



var count = 0



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// import { createFolder } from "../utils";



const Success = ({ theme, setTheme }) => {

  const dispatch = useDispatch()

  const object = useSelector((state) => state?.form?.form)

  const location = useLocation();
  const [apiCallMade, setApiCallMade] = useState(false);



  // useEffect(()=>{

  //   const urlSearchParams = new URLSearchParams(location.search);

  //   // Get the value of a specific query parameter
  //   const myParamValue = urlSearchParams.get('q');

  //   // Log the value to the console (or do something else with it)
  //   console.log('asdasdasdasdsad',myParamValue)
  //       // if(object?.stripeAccount === myParamValue){
  //         dispatch(addForm({}))
  //         dispatch(newPage(0))
  //           Axios.post(`${endPoint}/complete-form`, { form_id: object?.form_id, email: object?.email, legal: object?.legal, artist:object?.artist, account_id: object?.stripeAccount  }, {
  //           }).then(res => {
  //             console.log('asjahkhkahksas', res?.data)



  //           }).catch(err => {

  //           })
  //         //  }
  //         //  else{
  //         //   window.location.href = '/'
  //         //  }

  // },[object?.form_id])

  const urlSearchParams = new URLSearchParams(location.search);
  const myParamValue = urlSearchParams.get('q');


  useEffect(() => {


    if (count === 0) {
      count = count + 1


      if (object?.stripeAccount === myParamValue) {
        setApiCallMade(true); // Set the flag to true to indicate that the API call has been made
        console.log('jkahskdjhasjkdhakshdkasd', count)
        Axios.post(
          `${endPoint}/complete-form`,
          {
            form_id: object?.form_id,
            email: object?.email,
            legal: object?.legal,
            artist: object?.artist,
            account_id: object?.stripeAccount,
          },
        )
          .then((res) => {
            console.log('API Response:', res?.data);
            dispatch(addForm({}));
            dispatch(newPage(0));
          })
          .catch((err) => {
            console.error('API Error:', err);
          });
      } else {
        window.location.href = '/'

      }

    }
  }, [object?.form_id, apiCallMade, dispatch, location.search]);










  return (
    <div className="sing-area">


      <Header logo={theme === "light-theme" ? logoLight : logoDark} />
      <div>
        <form action="" className="sign-form">











          <div className="sign-box">
            <div className="form-title">Gagged us a bit for sure!</div>
            <p style={{ textAlign: 'center' }}> Thanks for joining Sickening Shop! While we work on getting your collection live please check us out on socials! </p>

            <p>
              Collections usually go live in 48-72 hours, but we’ll send you a link when it’s ready!
            </p>

            <p>
              Sickening Shop on <a href="https://instagram.com/sickening.shop" target="_blank">Instagram</a><br />
              Sickening Events on <a href="https://instagram.com/sickening.events" target="_blank">Instagram</a><br />
              Sickening Events on <a href="https://tiktok.com/@sickeningevents" target="_blank">TikTok</a><br />
              Sickening Events on <a href="https://youtube.com/sickeningevents" target="_blank">YouTube</a><br />
              Sickening Events Online at <a href="https://sickeningevents.com" target="_blank">sickeningevents.com</a><br />
            </p>

            <p>
              About the Owner: Reid Lawrence on <a href="https://instagram.com/electricreid" target="_blank">Instagram</a>
            </p>

          </div>






        </form>
      </div>
      <Footer
        theme={theme}
        setTheme={setTheme}
        logo={theme === "light-theme" ? logoLight : logoDark}
      />
    </div>
  );
};

export default Success;
