import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignFormUp from "./Components/SignFormUp";
// import SignFormIn from "./Components/SignFormIn";
import Success from "./Components/Success";

import { useEffect, useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { endPoint } from "./api";



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

//console.log('asjkkdhkjasdhkasjdhkasjdhaksdj', process.env.REACT_APP_STRIPE_PUBLISH_KEY, process.env.REACT_APP_BACKEND)
function App() {
  const [theme, setTheme] = useState("dark-theme");
  const [clientSecret, setClientSecret] = useState('');  // by manjeet

  useEffect(() => {
    // Fetch the clientSecret from your backend
    const fetchClientSecret = async () => {
      const response = await fetch(endPoint + '/get-stipe-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
      console.log(data.clientSecret);
    };

    fetchClientSecret();
  }, []);


  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret //'seti_1OSYdpFb2x0JpdjAn2OMte6q_secret_PH6raN3CLkoLSjyCo3c5OxdfAyQniqn',
  };

  useEffect(() => {
    console.log("First Step");
    if (theme === "dark-theme") {
      document.querySelector("body").setAttribute("data-theme", "dark-theme");
    } else {
      document.querySelector("body").setAttribute("data-theme", "dark-theme");
    }
  }, [theme]);

  useEffect(() => {
    console.log("Second Step");
    const localTheme = localStorage.getItem("theme");
    setTheme(localTheme);
  }, []);

  return (
    <div className="App">
      {clientSecret && (<Elements stripe={stripePromise} options={options}>

        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<SignFormUp theme={theme} setTheme={setTheme} />}
            />

            <Route
              path="/success"
              element={<Success theme={theme} setTheme={setTheme} />}
            />

          </Routes>
        </BrowserRouter>
      </Elements>)}

    </div>
  );
}

export default App;
