import React from "react";
import Sun from "../../Assets/Icons/Sun";
import Moon from "../../Assets/Icons/Moon";
import { Link } from "react-router-dom";

const Footer = ({ theme, setTheme, logo }) => {
  return (
    <div className="mt-auto">
      <div className="sign-footer">
        <Link to="/"><img src={logo} alt="" height={40} /></Link>

        <p>Copyright ©️ 2024 Sickening Shop LLC</p>
      </div>
    </div>
  );
};

export default Footer;
