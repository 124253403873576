import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Google from "../Assets/Icons/Google";
import Facebook from "../Assets/Icons/Facebook";
import Footer from "./Global/Footer";
import Header from "./Global/Header";
import logoLight from "../Assets/images/logo.png";
import logoDark from "../Assets/images/logo.png";
import { useSelector, useDispatch } from 'react-redux'
import { addForm, newPage, promoImages } from "../reducer/formReducer"
import Axios, { formToJSON } from 'axios'
import { endPoint } from "../api";
import '../index.css';
import TC from "./TC";

import Loader from './Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Elements, CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ACK from "./ACK";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// import { createFolder } from "../utils";



const SignFormUp = ({ theme, setTheme }) => {


  const [loading, setLoading] = useState(false);



  const stripe = useStripe();
  const elements = useElements();

  const cardOptions = {
    hidePostalCode: true,






    style: {
      base: {
        fontSize: '20px',
        backgroundColor: '#ffeedd',
        // innerHeight:'100px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
  }

  const currentPage = useSelector((state) => state?.form?.currentPage)

  const object = useSelector((state) => state?.form?.form)



  const [errors, setErrors] = useState({
    legal: false,
    artist: false,
    email: false,
    phone: false,
    social: false,
    city: false,
    imageSize: false,
    acknowledge: false,
    toc: false,
    tocacknowledge: false,


    merchCount: false,
    images: false,
    merch1Img: false,
    merch1Select: false,
    merch2Img: false,
    merch2Select: false,
    merch3Img: false,
    merch3Select: false,
    merch4Img: false,
    merch4Select: false,
  })



  const dispatch = useDispatch()

  useEffect(() => {
    if (!object?.form_id) {
      dispatch(newPage(0))
    }
  }, [])

  // dispatch(newPage(5))

  useEffect(() => {
    console.log('jashkdjashkjdhaksjdas object', object)


  }, [object])
  useEffect(() => {

    console.log('akjhdkasdhkjsahd', errors)
  }, [errors])

  function checkIfAnyTrue(obj) {
    return Object.values(obj).some(value => value === true);
  }

  function validatePhoneNumber(phoneNumber) {
    // Regular expression for US phone numbers
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    // Check if the provided phone number matches the pattern
    return phoneRegex.test(phoneNumber);
  }

  function validateEmail(email) {
    // Regular expression for email addresses
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    // Check if the provided email matches the pattern
    return emailRegex.test(email);
  }

  function objectToFormData(obj, parentKey, formData) {






    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let formKey = parentKey ? `${parentKey}${key}` : key;

        if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
          // objectToFormData(obj[key], key, formData);
        } else {

          console.log('Apend...', formKey, obj[key])

          formData.append(`${formKey}`, obj[key]);

        }
      }
    }

    return formData;
  }

  const formatPhoneNumber = (input) => {
    // Remove any non-numeric characters
    const phoneNumber = input.replace(/\D/g, '');

    // Format the phone number as desired
    const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    return formattedPhoneNumber;
  };


  const handleChange0 = (label, value) => {

    let copyObject

    if (label === 'images') {
      console.log('kjahsdkaskjdhasjkdhaksjd', value[0].size / (1024 * 1024))
    }

    if (label === 'phone') {



      copyObject = {
        ...object,
        [label]: formatPhoneNumber(value)
      }



    }


    else {

      copyObject = {
        ...object,
        [label]: value
      }
    }

    // if(label==='images'){
    //   promoImages(value)
    // }

    // else{





    // }



    const errCopy = JSON.parse(JSON.stringify(errors))


    if (label === "email") {
      if (!validateEmail(object['email'])) {

        errCopy['email'] = true


      }
      else {
        errCopy['email'] = false

      }

      setErrors(() => {
        return errCopy
      })

    }





    dispatch(addForm(copyObject))



  }

  const uploadPromoImages = (label, value) => {
    const copyObject = {
      ...object,
      [label]: value,
      promoImages: []
    }

    dispatch(addForm(copyObject))



  }

  const handleChange1 = (label, value) => {
    const copyObject = {
      ...object,
      merch1: {
        ...object?.merch1,
        [label]: value
      }
    }


    dispatch(addForm(copyObject))



  }
  const handleChange2 = (label, value) => {
    const copyObject = {
      ...object,
      merch2: {
        ...object?.merch2,
        [label]: value
      }
    }


    dispatch(addForm(copyObject))


  }
  const handleChange3 = (label, value) => {
    const copyObject = {
      ...object,
      merch3: {
        ...object?.merch3,
        [label]: value
      }
    }


    dispatch(addForm(copyObject))



  }
  const handleChange4 = (label, value) => {
    const copyObject = {
      ...object,
      merch4: {
        ...object?.merch4,
        [label]: value
      }
    }


    dispatch(addForm(copyObject))


  }

  const handleAcknowledge = (label, value) => {
    console.log('kahdkjashdkjashdjkasd', label)
    const copyObject = {
      ...object,
      [label]: !object?.acknowledge
    }


    dispatch(addForm(copyObject))


  }

  const handleToc = (label, value) => {
    console.log('kahdkjashdkjashdjkasd', label, value)
    const copyObject = {
      ...object,
      toc: {
        ...object?.toc,
        [label]: value
      }
    }


    dispatch(addForm(copyObject))


  }
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: adds smooth scrolling animation
    });

  }
  const handleNext = async (e) => {
    console.log(currentPage);
    setLoading(true)
    e.preventDefault();




    const errCopy = JSON.parse(JSON.stringify(errors))

    if (currentPage === 0) {
      errCopy['legal'] = !object?.legal ? true : false
      errCopy['artist'] = !object?.artist ? true : false
      errCopy['email'] = !object?.email ? true : false
      errCopy['social'] = !object?.social ? true : false
      errCopy['phone'] = !object?.phone ? true : false
      errCopy['city'] = !object?.city ? true : false

      let imagesError = false
      errCopy['images'] = (object?.images?.length < 5 || object?.images?.length > 10) ? true : false
      if (object?.images?.length) {

        for (const img of object?.images) {
          let size = img.size / (1024 * 1024)

          if (size > 15) {
            imagesError = true
          }
        }

      }
      errCopy['imageSize'] = imagesError ? true : false
      setErrors(() => {
        return errCopy
      })
    }
    else if (currentPage === 1) {

      errCopy['merchCount'] = !object?.merchCount ? true : false
      setErrors(() => {
        return errCopy
      })

    }
    else if (currentPage === 2) {
      errCopy['merch1Select'] = !object?.merch1?.select ? true : false
      errCopy['merch1Img'] = !object?.merch1?.image?.name ? true : false

      setErrors(() => {
        return errCopy
      })
    }
    else if (currentPage === 3) {
      errCopy['merch2Select'] = !object?.merch2?.select ? true : false
      errCopy['merch2Img'] = !object?.merch2?.image?.name ? true : false

      setErrors(() => {
        return errCopy
      })
    }
    else if (currentPage === 4) {
      errCopy['merch3Select'] = !object?.merch3?.select ? true : false
      errCopy['merch3Img'] = !object?.merch3?.image?.name ? true : false

      setErrors(() => {
        return errCopy
      })

    }
    else if (currentPage === 5) {
      errCopy['merch4Select'] = !object?.merch4?.select ? true : false
      errCopy['merch4Img'] = !object?.merch4?.image?.name ? true : false

      setErrors(() => {
        return errCopy
      })
    }

    else if (currentPage === 6) {
      errCopy['acknowledge'] = !object?.acknowledge ? true : false

      setErrors(() => {
        return errCopy
      })
    }

    else if (currentPage === 7) {
      errCopy['toc'] = !object?.toc?.sign ? true : false
      errCopy['tocacknowledge'] = !object?.toc?.acknowledge ? true : false


      setErrors(() => {
        return errCopy
      })
    }


    if ((checkIfAnyTrue(errCopy))) {
      setLoading(false)

      return
    }

    // Upload images

    async function uploadAndCreateForm(object, currentPage) {
      try {
        // Create form data for the new form
        let formData = new FormData();
        let data = objectToFormData(object, null, formData);
        data.append('parent', '1qaAdHYhpGlVHZJZKpHv4ArA9rzKXghjc');

        // Post the form data to create a new form
        const formResponse = await Axios.post(`${endPoint}/create-form`, data, {
          headers: { "Accept": "application/json, text/plain, /", "Content-Type": "multipart/form-data" },
        });

        console.log('Form created:', formResponse?.data?.data);

        const newObject = {
          ...object,
          form_id: formResponse?.data?.data?._id,
          artist: formResponse?.data?.data?.artist,
          promo_folder: formResponse?.data?.data?.promoFolder,
        };

        // Convert images object to an array
        const imagesArray = Object.keys(newObject.images).map(key => newObject.images[key]);

        // Create an array of upload promises using the newObject with updated form_id
        const uploadPromises = imagesArray.map((img, index) => {
          let formData = new FormData();
          formData.append('form_id', newObject.form_id);
          formData.append('promo_folder', newObject.promo_folder);
          formData.append('artist', newObject.artist);
          formData.append('index', index + 1);
          formData.append('files', img);

          return Axios.post(`${endPoint}/upload-promo`, formData, {
            headers: { "Accept": "application/json, text/plain, /", "Content-Type": "multipart/form-data" },
          });
        });

        // Wait for all uploads to complete
        const responses = await Promise.all(uploadPromises);

        // Extract uploaded data from responses
        const uploaded = responses.map(res => res?.data?.upload);

        console.log('All uploads completed:', uploaded);

        const copyObject = {
          ...newObject,
          uploadedPromos: uploaded,
        };

        dispatch(addForm(JSON.parse(JSON.stringify(copyObject))));
        setLoading(false);
        dispatch(newPage(currentPage + 1));
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        toast(error?.response?.data?.message || 'An error occurred');
      }
    }



    if (currentPage === 0) {
      uploadAndCreateForm(object, currentPage);
    }
    else if (currentPage === 1) {
      let formData = new FormData();
      formData.append('form_id', object?.form_id)
      formData.append('merchCount', object?.merchCount)
      Axios.post(`${endPoint}/add-merch-count`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(res => {
        setLoading(false)
        scrollUp()
        const copyObject = {
          ...object,
          form_id: res?.data?.data?._id,
          merchCount: res?.data?.data?.merchCount
        }
        dispatch(addForm(copyObject))
        if (currentPage === 1 && object?.merchCount === 1) {
          dispatch(newPage(currentPage + 4))
        } else if (currentPage === 1 && object?.merchCount === 2) {
          dispatch(newPage(currentPage + 3))

        } else if (currentPage === 1 && object?.merchCount === 3) {
          dispatch(newPage(currentPage + 2))

        } else {
          dispatch(newPage(currentPage + 1))
        }
      }).catch(err => {
        setLoading(false)
      });
    }
    else if (currentPage === 2 || currentPage === 3 || currentPage === 4 || currentPage === 5) {
      console.log(currentPage);
      let formData = new FormData();
      formData.append('form_id', object?.form_id)
      formData.append('artist', object?.artist)
      formData.append('parent', '1-CaatbtzHunsmN-ZekQQ-ol3NsxEb19u')
      if (object?.merch_folder) formData.append('folderId', object?.merch_folder)
      formData.append('files', object[`merch${currentPage - 1}`]?.image)
      formData.append('select', object[`merch${currentPage - 1}`]?.select)
      formData.append('text1', object[`merch${currentPage - 1}`]?.text1)
      formData.append('text2', object[`merch${currentPage - 1}`]?.text2)
      Axios.post(`${endPoint}/update-merch-form`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(res => {
        console.log('asjahkhkahksas', res?.data)
        setLoading(false)
        scrollUp()
        const copyObject = {
          ...object,
          form_id: res?.data?.data?._id,
          merch_folder: res?.data?.data?.merchFolder
        }
        dispatch(addForm(copyObject))
        // dispatch(newPage(currentPage + 1))
        dispatch(newPage(6))
      }).catch(err => {
        setLoading(false)
      })
    }
    else if (currentPage === 6) {
      Axios.post(`${endPoint}/add-ack`, { form_id: object?.form_id, acknowledge: object?.acknowledge }, {
      }).then(res => {
        console.log('asjahkhkahksas', res?.data)
        setLoading(false)

        scrollUp()



        dispatch(newPage(currentPage + 1))


      }).catch(err => {
        setLoading(false)

      })
    }
    else if (currentPage === 7) {
      Axios.post(`${endPoint}/add-sign`, { form_id: object?.form_id, sign: object?.toc?.sign, date: new Date }, {
      }).then(res => {
        console.log('asjahkhkahksas', res?.data)
        setLoading(false)
        scrollUp()
        dispatch(newPage(currentPage + 1))
      }).catch(err => {
        setLoading(false)

      })
    }
    else if (currentPage === 8) {
      let pm = elements?.getElement(CardElement);
      console.log(pm);
      stripe.createPaymentMethod({
        type: 'card',
        card: pm,
      })?.then((res) => {
        Axios.post(`${endPoint}/create-account`, { form_id: object?.form_id, email: object?.email, legal: object?.legal, artist: object?.artist, pm: res?.paymentMethod?.id }, {
        }).then(res => {

          const copyObject = {
            ...object,
            stripeAccount: res?.data?.data?.stripeAccount,
          }
          dispatch(addForm(copyObject))
          setLoading(false)
          scrollUp()
          dispatch(newPage(9))
        }).catch(error => {
          console.log('Payment-Data-Error', error)
          setLoading(false)
        })
      }).catch(err => {
        setLoading(false)

      })
    }
    else if (currentPage === 9) {
      Axios.post(`${endPoint}/connect-account`, { stripeAccount: object?.stripeAccount }, {
      }).then(res => {
        console.log('Coonetion Established', res?.data)
        window.open(res?.data?.data, '_self')
        setLoading(false)
      })
    }
    else {
      // dispatch(newPage(6))
    }

  }
  return (
    <div className="sing-area">

      {loading && <Loader />}
      <ToastContainer />
      <Header logo={theme === "light-theme" ? logoLight : logoDark} />
      <div>


        <form action="" className="sign-form">
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: `${(currentPage / 10) * 100}%` }} aria-valuenow={(currentPage / 10) * 100} aria-valuemin="0" aria-valuemax="100">{`${Math.round((currentPage / 10) * 100) ? Math.round((currentPage / 10) * 100) : 0}%`}</div>

          </div>
          {
            currentPage === 0 &&
            <div className="sign-box">
              <div className="form-title">JOIN SICKENING SHOP</div>
              <div className="form-subtitle">Our free onboarding takes minutes!</div>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  value={object?.email}
                  placeholder="andrew@domain.com"
                  onChange={(e) => { handleChange0('email', e.target.value) }}

                />
                <label htmlFor="floatingInput">Email</label>
              </div>
              {
                errors?.email && !object?.email ? <label className="requiredError">Email field is required</label> : ""
              }

              {
                (errors?.email && object?.email) ? <label className="requiredError">Email format is not valid</label> : ""
              }
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Andrew John"
                  value={object?.legal}
                  onChange={(e) => { handleChange0('legal', e.target.value) }}
                />
                <label htmlFor="floatingInput">Legal Name</label>
              </div>
              {
                errors?.legal && !object?.legal ? <label className="requiredError">Legal field is required</label> : ""
              }
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  value={object?.artist}
                  id="floatingInput"
                  placeholder="Christmas Special"
                  onChange={(e) => { handleChange0('artist', e.target.value) }}

                />
                <label htmlFor="floatingPassword">Artist or Group Name</label>
              </div>
              {
                errors?.artist && !object?.artist ? <label className="requiredError">Artist or Group field is required</label> : ""
              }
              <div className="form-floating">
                <input
                  type="tel"
                  id="phone"
                  value={object?.phone}
                  pattern={"[0-9]{3}-[0-9]{2}-[0-9]{3}"}
                  className="form-control"
                  placeholder="+1 103 103 1034"
                  onChange={(e) => { handleChange0('phone', e.target.value) }}

                />
                <label htmlFor="floatingInput">Phone</label>
              </div>
              {
                errors?.phone && !object?.phone ? <label className="requiredError">Phone field is required</label> : ""
              }

              {
                (!validatePhoneNumber(object['phone']) && object?.phone) ? <label className="requiredError">Phone should be in this format (123-456-7890)</label> : ""
              }



              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                  value={object?.city}
                  onChange={(e) => { handleChange0('city', e.target.value) }}

                />
                <label htmlFor="floatingInput">Where are you based? (Nearest City, State)</label>
              </div>
              {
                errors?.city && !object?.city ? <label className="requiredError">City field is required</label> : ""
              }

              <div className="form-floating">
                <input
                  type="text"
                  id="social"
                  value={object?.social}
                  pattern={"[0-9]{3}-[0-9]{2}-[0-9]{3}"}
                  className="form-control"
                  placeholder="@john"
                  onChange={(e) => { handleChange0('social', e.target.value) }}

                />
                <label htmlFor="floatingInput">Social Media Handles</label>
              </div>
              {
                errors?.social && !object?.social ? <label className="requiredError">social handler field is required</label> : ""
              }


              <div className="form-floating">
                <input
                  type="text"
                  id="social"
                  value={object?.referrel}
                  pattern={"[0-9]{3}-[0-9]{2}-[0-9]{3}"}
                  className="form-control"
                  placeholder="@john"
                  onChange={(e) => { handleChange0('referrel', e.target.value) }}

                />
                <label htmlFor="floatingInput">Referred by</label>
              </div>

              <div className="form-title">Upload Promo Images</div>
              <p>Please upload 5 to 10 High Resolution Promo photos of
                Your artist persona for use on Sickening Shop Site, Social Media, and promotions
              </p>
              <div className="form-floating">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => { uploadPromoImages('images', e?.target?.files) }}
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                  multiple
                />
              </div>
              {
                errors?.images && (object?.images?.length < 5 || object?.images?.length > 10) ? <label className="requiredError">Minimum 5 and Maximum 10 Images are required</label> : ""

              }
              {
                errors?.imageSize && <label className="requiredError">Make sure the image size should be max 15 MB</label>

              }
            </div>
          }
          {
            currentPage === 1 &&
            <div className="sign-box">
              <div className="merch-count">
                <label className="merches-title">Please Select how many Merch items you would like in your store:</label>
                <label className="container">1 Merch Item
                  <input type="checkbox" checked={object?.merchCount === 1} onChange={(e) => { handleChange0('merchCount', 1) }} />
                  <span className="checkmark"></span>
                </label>
                <label className="container">2 Merch Items
                  <input type="checkbox" checked={object?.merchCount === 2} onChange={(e) => { handleChange0('merchCount', 2) }} />
                  <span className="checkmark"></span>
                </label>
                <label className="container">3 Merch Items
                  <input type="checkbox" checked={object?.merchCount === 3} onChange={(e) => { handleChange0('merchCount', 3) }} />
                  <span className="checkmark"></span>
                </label>
                <label className="container">4 Merch Items
                  <input type="checkbox" checked={object?.merchCount === 4} onChange={(e) => { handleChange0('merchCount', 4) }} />
                  <span className="checkmark"></span>
                </label>
                {
                  errors?.merchCount && !object?.merchCount ? <label className="requiredError">Merch Count is required</label> : ""
                }
                <label style={{ textAlign: 'center' }}>
                  Don’t worry, if you have more merch items you can add them later.
                </label>
              </div>
            </div>
          }
          {
            currentPage === 2 &&
            <div className="sign-box">
              <div className="form-title">Select your merch items. (Get the bag sis)</div>
              <div className="form-subtitle">Provide your logo and artwork, and select your products! Don’t Worry, we’ll send you all the mockups before anything goes live! </div>




              <div className="promo-subtitle">Merch Item Setup</div>
              <p>
                Please upload up a transparent PNG image of your logo, art, or other media for use on your merch item.  Minimum DPI: 300

              </p>


              <div className="form-floating">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => { handleChange1('image', e.target.files[0]) }}
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                />
              </div>
              {
                errors?.merch1Img ? <label className="requiredError">Image is required</label> : ""
              }
              <div className="merch-select">


                <label className="container">Unisex T-shirt
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Unisex T-shirt'} onChange={() => handleChange1('select', 'Unisex T-shirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex Hoodie
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Unisex Hoodie'} onChange={() => handleChange1('select', 'Unisex Hoodie')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex Sweatshirt
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Unisex Sweatshirt'} onChange={() => handleChange1('select', 'Unisex Sweatshirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex long sleeve shirt
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Unisex long sleeve shirt'} onChange={() => handleChange1('select', 'Unisex long sleeve shirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Tank Top
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Tank Top'} onChange={() => handleChange1('select', 'Tank Top')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Athletic Joggers
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Athletic Joggers'} onChange={() => handleChange1('select', 'Athletic Joggers')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Phone Case
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Phone Case'} onChange={() => handleChange1('select', 'Phone Case')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Canvas tote bag
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Canvas tote bag'} onChange={() => handleChange1('select', 'Canvas tote bag')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Drawstring bag
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Drawstring bag'} onChange={() => handleChange1('select', 'Drawstring bag')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Crew length socks (half calf)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Crew length socks (half calf)'} onChange={() => handleChange1('select', 'Crew length socks (half calf)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Kitchen Apron
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Kitchen Apron'} onChange={() => handleChange1('select', 'Kitchen Apron')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Square Pot holder
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Square Pot holder'} onChange={() => handleChange1('select', 'Square Pot holder')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Kitchen Oven Mitt
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Kitchen Oven Mitt'} onChange={() => handleChange1('select', 'Kitchen Oven Mitt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Vanity Car Plate (12” x 6” All over print aluminum plate)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Vanity Car Plate (12” x 6” All over print aluminum plate)'} onChange={() => handleChange1('select', 'Vanity Car Plate (12” x 6” All over print aluminum plate)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">AirPods and AirPods Pro Case Cover
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'AirPods and AirPods Pro Case Cover'} onChange={() => handleChange1('select', 'AirPods and AirPods Pro Case Cover')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">12” x 7” Makeup/Tech accessory Pouch (Top Zip)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === '12” x 7” Makeup/Tech accessory Pouch (Top Zip)'} onChange={() => handleChange1('select', '12” x 7” Makeup/Tech accessory Pouch (Top Zip)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">3” Pin Button
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === '3” Pin Button'} onChange={() => handleChange1('select', '3” Pin Button')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Ceramic Mug (11oz.)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Ceramic Mug (11oz.)'} onChange={() => handleChange1('select', 'Ceramic Mug (11oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Plastic Tumbler (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Plastic Tumbler (16oz.)'} onChange={() => handleChange1('select', 'Plastic Tumbler (16oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Aluminum Water Bottle (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Aluminum Water Bottle (16oz.)'} onChange={() => handleChange1('select', 'Aluminum Water Bottle (16oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)'} onChange={() => handleChange1('select', 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Gloss Poster (11x17)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Gloss Poster (11x17)'} onChange={() => handleChange1('select', 'Gloss Poster (11x17)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">9oz. Scented Candle
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === '9oz. Scented Candle'} onChange={() => handleChange1('select', '9oz. Scented Candle')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Velveteen Plush Blanket
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Velveteen Plush Blanket'} onChange={() => handleChange1('select', 'Velveteen Plush Blanket')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Square Pillow (16x16)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Square Pillow (16x16)'} onChange={() => handleChange1('select', 'Square Pillow (16x16)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Puzzle (250pcs)
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Puzzle (250pcs)'} onChange={() => handleChange1('select', 'Puzzle (250pcs)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Deck of Cards
                  <input type="radio" name="merchItem" checked={object?.merch1?.select === 'Deck of Cards'} onChange={() => handleChange1('select', 'Deck of Cards')} />
                  <span className="checkmark"></span>
                </label>


                {
                  errors?.merch1Select ? <label className="requiredError">Selection is required</label> : ""
                }
              </div>
              <label className="misLabel">Please indicate your preferred placement on the item if applicable. (Ex. Left breast 4” size, Largest print size possible centered, on the rear of garment, etc.)</label>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  onChange={(e) => { handleChange1('text1', e.target.value) }}
                />
              </div>
              <label className="misLabel">Please indicate color preference if applicable. 3 standard colors are included. (if none entered default colors are Black, White, and Red)</label>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Christmas Special"
                  onChange={(e) => { handleChange1('text2', e.target.value) }}

                />
              </div>
            </div>
          }

          {
            currentPage === 3 &&
            <div className="sign-box">
              <div className="form-title">Select your merch items (Get the shoes bby)</div>
              <div className="form-subtitle">Provide your logo and artwork, and select your products! Don’t Worry, we’ll send you all the mockups before anything goes live!  </div>


              <div className="promo-subtitle">Merch Item Setup</div>
              <p>
                Please upload up a transparent PNG image of your logo, art, or other media for use on your merch item.  Minimum DPI: 300

              </p>


              <div className="form-floating">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => { handleChange2('image', e.target.files[0]) }}
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                />
              </div>
              {
                errors?.merch2Img ? <label className="requiredError">Image is required</label> : ""
              }
              <div className="merch-select">


                <label className="container">Unisex T-shirt
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Unisex T-shirt'} onChange={() => handleChange2('select', 'Unisex T-shirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex Hoodie
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Unisex Hoodie'} onChange={() => handleChange2('select', 'Unisex Hoodie')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex Sweatshirt
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Unisex Sweatshirt'} onChange={() => handleChange2('select', 'Unisex Sweatshirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Unisex long sleeve shirt
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Unisex long sleeve shirt'} onChange={() => handleChange2('select', 'Unisex long sleeve shirt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Tank Top
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Tank Top'} onChange={() => handleChange2('select', 'Tank Top')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Athletic Joggers
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Athletic Joggers'} onChange={() => handleChange2('select', 'Athletic Joggers')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Phone Case
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Phone Case'} onChange={() => handleChange2('select', 'Phone Case')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Canvas tote bag
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Canvas tote bag'} onChange={() => handleChange2('select', 'Canvas tote bag')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Drawstring bag
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Drawstring bag'} onChange={() => handleChange2('select', 'Drawstring bag')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Crew length socks (half calf)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Crew length socks (half calf)'} onChange={() => handleChange2('select', 'Crew length socks (half calf)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Kitchen Apron
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Kitchen Apron'} onChange={() => handleChange2('select', 'Kitchen Apron')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Square Pot holder
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Square Pot holder'} onChange={() => handleChange2('select', 'Square Pot holder')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Kitchen Oven Mitt
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Kitchen Oven Mitt'} onChange={() => handleChange2('select', 'Kitchen Oven Mitt')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Vanity Car Plate (12” x 6” All over print aluminum plate)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Vanity Car Plate (12” x 6” All over print aluminum plate)'} onChange={() => handleChange2('select', 'Vanity Car Plate (12” x 6” All over print aluminum plate)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">AirPods and AirPods Pro Case Cover
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'AirPods and AirPods Pro Case Cover'} onChange={() => handleChange2('select', 'AirPods and AirPods Pro Case Cover')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">12” x 7” Makeup/Tech accessory Pouch (Top Zip)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === '12” x 7” Makeup/Tech accessory Pouch (Top Zip)'} onChange={() => handleChange2('select', '12” x 7” Makeup/Tech accessory Pouch (Top Zip)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">3” Pin Button
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === '3” Pin Button'} onChange={() => handleChange2('select', '3” Pin Button')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Ceramic Mug (11oz.)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Ceramic Mug (11oz.)'} onChange={() => handleChange2('select', 'Ceramic Mug (11oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Plastic Tumbler (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Plastic Tumbler (16oz.)'} onChange={() => handleChange2('select', 'Plastic Tumbler (16oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Aluminum Water Bottle (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Aluminum Water Bottle (16oz.)'} onChange={() => handleChange2('select', 'Aluminum Water Bottle (16oz.)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)'} onChange={() => handleChange2('select', 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Gloss Poster (11x17)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Gloss Poster (11x17)'} onChange={() => handleChange2('select', 'Gloss Poster (11x17)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">9oz. Scented Candle
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === '9oz. Scented Candle'} onChange={() => handleChange2('select', '9oz. Scented Candle')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Velveteen Plush Blanket
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Velveteen Plush Blanket'} onChange={() => handleChange2('select', 'Velveteen Plush Blanket')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Square Pillow (16x16)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Square Pillow (16x16)'} onChange={() => handleChange2('select', 'Square Pillow (16x16)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Puzzle (250pcs)
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Puzzle (250pcs)'} onChange={() => handleChange2('select', 'Puzzle (250pcs)')} />
                  <span className="checkmark"></span>
                </label>

                <label className="container">Deck of Cards
                  <input type="radio" name="merchItem" checked={object?.merch2?.select === 'Deck of Cards'} onChange={() => handleChange2('select', 'Deck of Cards')} />
                  <span className="checkmark"></span>
                </label>


                {
                  errors?.merch2Select ? <label className="requiredError">Selection is required</label> : ""
                }

              </div>
              <label className="misLabel">Please indicate your preferred placement on the item if applicable. (Ex. Left breast 4” size, Largest print size possible centered, on the rear of garment, etc.)</label>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  onChange={(e) => { handleChange2('text1', e.target.value) }}
                />
              </div>
              <label className="misLabel">Please indicate color preference if applicable. 3 standard colors are included. (if none entered default colors are Black, White, and Red)</label>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Christmas Special"
                  onChange={(e) => { handleChange2('text2', e.target.value) }}

                />
              </div>
            </div>
          }
          {
            currentPage === 4 &&
            <div className="sign-box">
              <div className="form-title">Select your merch items (Camp is in!)</div>
              <div className="form-subtitle">Provide your logo and artwork, and select your products! Don’t Worry, we’ll send you all the mockups before anything goes live!  </div>



              <div className="promo-subtitle">Merch Item Setup</div>
              <p>
                Please upload up a transparent PNG image of your logo, art, or other media for use on your merch item.  Minimum DPI: 300

              </p>






              <div className="form-floating">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => { handleChange3('image', e.target.files[0]) }}
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                />
              </div>

              {
                errors?.merch3Img ? <label className="requiredError">Image is required</label> : ""
              }
              <div className="merch-select">




                <label className="container">Unisex T-shirt
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Unisex T-shirt'} onChange={() => handleChange3('select', 'Unisex T-shirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex Hoodie
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Unisex Hoodie'} onChange={() => handleChange3('select', 'Unisex Hoodie')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex Sweatshirt
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Unisex Sweatshirt'} onChange={() => handleChange3('select', 'Unisex Sweatshirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex long sleeve shirt
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Unisex long sleeve shirt'} onChange={() => handleChange3('select', 'Unisex long sleeve shirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Tank Top
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Tank Top'} onChange={() => handleChange3('select', 'Tank Top')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Athletic Joggers
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Athletic Joggers'} onChange={() => handleChange3('select', 'Athletic Joggers')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Phone Case
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Phone Case'} onChange={() => handleChange3('select', 'Phone Case')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Canvas tote bag
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Canvas tote bag'} onChange={() => handleChange3('select', 'Canvas tote bag')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Drawstring bag
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Drawstring bag'} onChange={() => handleChange3('select', 'Drawstring bag')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Crew length socks (half calf)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Crew length socks (half calf)'} onChange={() => handleChange3('select', 'Crew length socks (half calf)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Kitchen Apron
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Kitchen Apron'} onChange={() => handleChange3('select', 'Kitchen Apron')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Square Pot holder
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Square Pot holder'} onChange={() => handleChange3('select', 'Square Pot holder')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Kitchen Oven Mitt
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Kitchen Oven Mitt'} onChange={() => handleChange3('select', 'Kitchen Oven Mitt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Vanity Car Plate (12” x 6” All over print aluminum plate)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Vanity Car Plate (12” x 6” All over print aluminum plate)'} onChange={() => handleChange3('select', 'Vanity Car Plate (12” x 6” All over print aluminum plate)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">AirPods and AirPods Pro Case Cover
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'AirPods and AirPods Pro Case Cover'} onChange={() => handleChange3('select', 'AirPods and AirPods Pro Case Cover')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">12” x 7” Makeup/Tech accessory Pouch (Top Zip)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === '12” x 7” Makeup/Tech accessory Pouch (Top Zip)'} onChange={() => handleChange3('select', '12” x 7” Makeup/Tech accessory Pouch (Top Zip)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">3” Pin Button
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === '3” Pin Button'} onChange={() => handleChange3('select', '3” Pin Button')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Ceramic Mug (11oz.)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Ceramic Mug (11oz.)'} onChange={() => handleChange3('select', 'Ceramic Mug (11oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Plastic Tumbler (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Plastic Tumbler (16oz.)'} onChange={() => handleChange3('select', 'Plastic Tumbler (16oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Aluminum Water Bottle (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Aluminum Water Bottle (16oz.)'} onChange={() => handleChange3('select', 'Aluminum Water Bottle (16oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)'} onChange={() => handleChange3('select', 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Gloss Poster (11x17)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Gloss Poster (11x17)'} onChange={() => handleChange3('select', 'Gloss Poster (11x17)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">9oz. Scented Candle
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === '9oz. Scented Candle'} onChange={() => handleChange3('select', '9oz. Scented Candle')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Velveteen Plush Blanket
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Velveteen Plush Blanket'} onChange={() => handleChange3('select', 'Velveteen Plush Blanket')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Square Pillow (16x16)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Square Pillow (16x16)'} onChange={() => handleChange3('select', 'Square Pillow (16x16)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Puzzle (250pcs)
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Puzzle (250pcs)'} onChange={() => handleChange3('select', 'Puzzle (250pcs)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Deck of Cards
                  <input type="radio" name="merchItem" checked={object?.merch3?.select === 'Deck of Cards'} onChange={() => handleChange3('select', 'Deck of Cards')} />
                  <span className="checkmark"></span>
                </label>




                {
                  errors?.merch3Select ? <label className="requiredError">Selection is required</label> : ""
                }

              </div>
              <label className="misLabel">Please indicate your preferred placement on the item if applicable. (Ex. Left breast 4” size, Largest print size possible centered, on the rear of garment, etc.)</label>


              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  onChange={(e) => { handleChange3('text1', e.target.value) }}
                />
              </div>
              <label className="misLabel">Please indicate color preference if applicable. 3 standard colors are included. (if none entered default colors are Black, White, and Red)</label>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Christmas Special"
                  onChange={(e) => { handleChange3('text2', e.target.value) }}


                />
              </div>
            </div>
          }
          {
            currentPage === 5 &&
            <div className="sign-box">
              <div className="form-title">Select your merch items (No Cap)</div>
              <div className="form-subtitle">Provide your logo and artwork, and select your products! Don’t Worry, we’ll send you all the mockups before anything goes live!  </div>




              <div className="promo-subtitle">Merch Item Setup</div>
              <p>
                Please upload up a transparent PNG image of your logo, art, or other media for use on your merch item.  Minimum DPI: 300

              </p>





              <div className="form-floating">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => { handleChange4('image', e.target.files[0]) }}
                  className="form-control"
                  id="floatingInput"
                  placeholder="New York"
                />
              </div>
              {
                errors?.merch4Img ? <label className="requiredError">Image is required</label> : ""
              }
              <div className="merch-select">




                <label className="container">Unisex T-shirt
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Unisex T-shirt'} onChange={() => handleChange4('select', 'Unisex T-shirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex Hoodie
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Unisex Hoodie'} onChange={() => handleChange4('select', 'Unisex Hoodie')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex Sweatshirt
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Unisex Sweatshirt'} onChange={() => handleChange4('select', 'Unisex Sweatshirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Unisex long sleeve shirt
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Unisex long sleeve shirt'} onChange={() => handleChange4('select', 'Unisex long sleeve shirt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Tank Top
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Tank Top'} onChange={() => handleChange4('select', 'Tank Top')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Athletic Joggers
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Athletic Joggers'} onChange={() => handleChange4('select', 'Athletic Joggers')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Phone Case
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Phone Case'} onChange={() => handleChange4('select', 'Phone Case')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Canvas tote bag
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Canvas tote bag'} onChange={() => handleChange4('select', 'Canvas tote bag')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Drawstring bag
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Drawstring bag'} onChange={() => handleChange4('select', 'Drawstring bag')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Crew length socks (half calf)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Crew length socks (half calf)'} onChange={() => handleChange4('select', 'Crew length socks (half calf)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Kitchen Apron
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Kitchen Apron'} onChange={() => handleChange4('select', 'Kitchen Apron')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Square Pot holder
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Square Pot holder'} onChange={() => handleChange4('select', 'Square Pot holder')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Kitchen Oven Mitt
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Kitchen Oven Mitt'} onChange={() => handleChange4('select', 'Kitchen Oven Mitt')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Vanity Car Plate (12” x 6” All over print aluminum plate)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Vanity Car Plate (12” x 6” All over print aluminum plate)'} onChange={() => handleChange4('select', 'Vanity Car Plate (12” x 6” All over print aluminum plate)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">AirPods and AirPods Pro Case Cover
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'AirPods and AirPods Pro Case Cover'} onChange={() => handleChange4('select', 'AirPods and AirPods Pro Case Cover')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">12” x 7” Makeup/Tech accessory Pouch (Top Zip)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === '12” x 7” Makeup/Tech accessory Pouch (Top Zip)'} onChange={() => handleChange4('select', '12” x 7” Makeup/Tech accessory Pouch (Top Zip)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">3” Pin Button
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === '3” Pin Button'} onChange={() => handleChange4('select', '3” Pin Button')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Ceramic Mug (11oz.)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Ceramic Mug (11oz.)'} onChange={() => handleChange4('select', 'Ceramic Mug (11oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Plastic Tumbler (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Plastic Tumbler (16oz.)'} onChange={() => handleChange4('select', 'Plastic Tumbler (16oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Aluminum Water Bottle (16oz.)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Aluminum Water Bottle (16oz.)'} onChange={() => handleChange4('select', 'Aluminum Water Bottle (16oz.)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)'} onChange={() => handleChange4('select', 'Matte Canvas Print (Pre stretched and wall-mount ready Sizes: 10x8, 14x11, 20x16)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Gloss Poster (11x17)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Gloss Poster (11x17)'} onChange={() => handleChange4('select', 'Gloss Poster (11x17)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">9oz. Scented Candle
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === '9oz. Scented Candle'} onChange={() => handleChange4('select', '9oz. Scented Candle')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Velveteen Plush Blanket
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Velveteen Plush Blanket'} onChange={() => handleChange4('select', 'Velveteen Plush Blanket')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Square Pillow (16x16)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Square Pillow (16x16)'} onChange={() => handleChange4('select', 'Square Pillow (16x16)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Puzzle (250pcs)
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Puzzle (250pcs)'} onChange={() => handleChange4('select', 'Puzzle (250pcs)')} />
                  <span className="checkmark"></span>
                </label>


                <label className="container">Deck of Cards
                  <input type="radio" name="merchItem" checked={object?.merch4?.select === 'Deck of Cards'} onChange={() => handleChange4('select', 'Deck of Cards')} />
                  <span className="checkmark"></span>
                </label>



                {
                  errors?.merch4Select ? <label className="requiredError">Selection is required</label> : ""
                }


              </div>
              <label className="misLabel">Please indicate your preferred placement on the item if applicable. (Ex. Left breast 4” size, Largest print size possible centered, on the rear of garment, etc.)</label>


              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  onChange={(e) => { handleChange4('text1', e.target.value) }}
                />
              </div>
              <label className="misLabel">Please indicate color preference if applicable. 3 standard colors are included. (if none entered default colors are Black, White, and Red)</label>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Christmas Special"
                  onChange={(e) => { handleChange4('text2', e.target.value) }}


                />
              </div>
            </div>
          }
          {
            currentPage === 6 &&
            <div className="sign-box">
              <div className="form-title">First things first (READ the house down)</div>
              <div className="form-subtitle">Some quick haus rules and housekeeping stuff  </div>

              <div className="legal__terms">
                <div className="legal__terms-scroll">
                  <div id="terms-and-conditions">
                    <ACK />
                  </div>
                </div>
              </div>
              <label className="container">Acknolwedge
                <input type="checkbox" name="acknowledge" checked={object?.acknowledge} onChange={() => handleAcknowledge('acknowledge')} />
                <span className="checkmark"></span>
              </label>
              {
                (errors?.acknowledge && !object?.acknowledge) ? <label className="requiredError">Please Acknowledge</label> : ""
              }



            </div>

          }
          {
            currentPage === 7 &&
            <div className="sign-box">
              <div className="form-title">Terms of Service (bc we have to…)</div>

              <div className="legal__terms">
                <div className="legal__terms-scroll">
                  <div id="terms-and-conditions">
                    <TC />
                  </div>
                </div>
              </div>
              <label className="container">I agree that this agreement may be electronically signed. I agree that the electronic signatures appearing on this agreement are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.
                <input type="checkbox" name="tocacknowledge" checked={object?.toc?.acknowledge} onChange={() => handleToc('acknowledge', !object?.toc?.acknowledge)} />
                <span className="checkmark"></span>
              </label>
              {
                (errors?.tocacknowledge && !object?.toc?.acknowledge) ? <label className="requiredError">Please Agree</label> : ""
              }

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Andrew John"
                  onChange={(e) => { handleToc('sign', e.target.value) }}
                />
                <label htmlFor="floatingInput">Sign</label>
              </div>

              {
                (errors?.toc && !object?.toc?.sign) ? <label className="requiredError">Please Sign</label> : ""
              }
            </div>
          }
          {
            currentPage === 8 &&
            <div className="sign-box">
              <div className="form-title">Wrapping Up</div>
              <div className="form-subtitle">We need a payment method on file just in case. You won’t be charged for this onboarding process</div>

              <div style={{
                width: "100%",
                height: "100%",
                borderStyle: "solid",
                border: "1px solid #CED4DA",
                padding: "10px",
                backgroundColor: '#ffeedd'
              }}>


                <CardElement options={cardOptions} />

              </div>



            </div>
          }

          {
            currentPage === 9 &&
            <div className="sign-box">
              <div className="form-title">Let’s get you paid!</div>
              <p style={{ textAlign: 'center' }}> Your subscription has been created. Now We need to get some information on how to pay you!</p>
            </div>
          }
          <div className="text-center">
            {
              currentPage < 10 &&
              <button className="btn-lg submit" type="submit" onClick={handleNext}>
                {currentPage < 9 ? "Next" : "Connect a debit card or checking account"}
              </button>
            }
          </div>
        </form>
      </div>
      <Footer
        theme={theme}
        setTheme={setTheme}
        logo={theme === "light-theme" ? logoLight : logoDark}
      />
    </div>
  );
};

export default SignFormUp;