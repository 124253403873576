import React from "react";
import { Link } from "react-router-dom";

const Header = ({ logo }) => {
  return (
    <div className="sign-header">
      <Link to="/"><img className="header-logo" src={logo} alt="" height={100} /></Link>
    </div>
  );
};

export default Header;