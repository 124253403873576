import React from 'react';

const Google = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <g clip-path="url(#clip0_430_240)">
        <mask id="mask0_430_240" maskUnits="userSpaceOnUse" x="0" y="1" width="31" height="30">
          <path d="M30.027 1.11145H0.901581V30.1115H30.027V1.11145Z" fill="white" />
        </mask>
        <g mask="url(#mask0_430_240)">
          <path d="M29.4592 15.8828C29.4592 14.6946 29.3631 13.8276 29.1553 12.9285H15.6398V18.2911H23.5731C23.4132 19.6238 22.5495 21.6307 20.6301 22.9793L20.6032 23.1589L24.8766 26.482L25.1726 26.5117C27.8917 23.9909 29.4592 20.282 29.4592 15.8828Z" fill="#4C8BF3" />
          <path d="M15.6402 30.0119C19.5268 30.0119 22.7897 28.7273 25.173 26.5117L20.6305 22.9794C19.4149 23.8303 17.7834 24.4244 15.6402 24.4244C11.8335 24.4244 8.60262 21.9037 7.45089 18.4196L7.28208 18.434L2.8386 21.886L2.78049 22.0481C5.14767 26.7685 10.01 30.0119 15.6402 30.0119Z" fill="#34A853" />
          <path d="M7.44727 18.4196C7.14338 17.5205 6.96751 16.5571 6.96751 15.5616C6.96751 14.5661 7.14338 13.6028 7.43129 12.7037L7.42324 12.5122L2.92408 9.00476L2.77687 9.07505C1.80124 11.0339 1.24142 13.2336 1.24142 15.5616C1.24142 17.8897 1.80124 20.0893 2.77687 22.0481L7.44727 18.4196Z" fill="#FFD584" />
          <path d="M15.6402 6.69882C18.3432 6.69882 20.1666 7.87089 21.2063 8.85036L25.2689 4.8685C22.7738 2.54042 19.5268 1.11145 15.6402 1.11145C10.01 1.11145 5.14767 4.35469 2.78049 9.07505L7.4349 12.7037C8.60261 9.21961 11.8335 6.69882 15.6402 6.69882Z" fill="#EA4335" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_430_240">
          <rect width="30" height="30" fill="white" transform="translate(0.550018 0.611389)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Google;