import { configureStore } from '@reduxjs/toolkit'
import formReducer from "./reducer/formReducer"
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  // Specify the reducers you want to persist
  whitelist: ['form', 'currentPage', 'promoImages'], // In this example, we persist the 'user' reducer
};

const persistedReducer = persistReducer(persistConfig, formReducer);
export const store = configureStore({
  reducer: {
    form: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.register', 'payload.rehydrate'],
      },
    }),
})

export const persistor = persistStore(store);

