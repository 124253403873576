// Loader.js
import React from 'react';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux'




const LoaderOverlay = styled.div`
  position: fixed;\
  font-size:50px;
  color:white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
`;

const Loader = () => {

  const currentPage = useSelector((state) => state?.form?.currentPage)

  const object = useSelector((state) => state?.form?.form)


  console.log('laksdjlkasjdlkasjdlsa', object?.images?.length, object?.uploadedPromos?.length)

  let totalLength = object?.images?.length
  return (
    (

  
      <LoaderOverlay>
        {currentPage !==1 && <div className="loader">Loading...</div>}
        {currentPage ===1 && <div className="loader">{object?.uploadedPromos?.length} images are uploaded...</div>}

      </LoaderOverlay>
    )
  )
};

export default Loader;
