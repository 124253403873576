import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        legal: "",
        artist: "",
        email: "",
        phone: "",
        social:"",
        city: "",
        referrel:"",
        images: [],
        uploadedPromos:[],
        merchCount: 0,
        merch1: {
            image: null,
            select: null,
            text1: "",
            text2: ""
        },
        merch2: {
            image: null,
            select: null,
            text1: "",
            text2: ""
        },
        merch3: {
            image: null,
            select: null,
            text1: "",
            text2: ""
        },
        merch4: {
            image:null,
            select: null,
            text1: "",
            text2: ""
        },
        acknowledge: false,
        toc: {
            acknowledge:false,
            sign: null,
            html: ""
        },
        stripeCustomer: "",
        stripeAccount: "",
    },
    currentPage : 0,
    promoImages:[]
}
export const formReducer = createSlice({
    name: 'form',
    initialState,
    reducers: {
        addForm: (state, action) => {
            state.form = action?.payload
        },
        newPage: (state, action) => {
            state.currentPage = action?.payload
        },

        promoImages: (state, action) => {
            state.promoImages = action?.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { addForm, newPage, promoImages } = formReducer.actions

export default formReducer.reducer