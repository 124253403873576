import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import jsPDF from 'jspdf';


const TC = ({ }) => {

    const object = useSelector((state) => state?.form?.form)



    return (
        <div id="mainPdf">

            <p className="c26">
                <span className="c16" />
            </p>
            <p className="c22">
                <span className="c12 c18">ARTIST STOREFRONT AGREEMENT</span>
            </p>
            <p className="c13">
                <span className="c8">This Artist Storefront Agreement (the “</span>
                <span className="c10 c8">Agreement</span>
                <span className="c8">
                    ”) is entered into as of the date first written below (the “
                </span>
                <span className="c10 c8">Effective Date</span>
                <span className="c8">
                    ”), by and between Sickening Shop LLC, incorporated under the laws of
                    Delaware, United States, having its principal place of business at 8 The
                    Green, STE A Dover DE, 19901 (the “
                </span>
                <span className="c10 c8">Company</span>
                <span className="c8">
                    ”), and the undersigned artist or entity utilizing Company’s services (the
                    “
                </span>
                <span className="c8 c10">Client</span>
                <span className="c8">
                    ”). The Company and Client shall collectively be referred to herein as the
                    “
                </span>
                <span className="c10 c8">Parties</span>
                <span className="c8">” and individually as “</span>
                <span className="c10 c8">Party</span>
                <span className="c2">.”</span>
            </p>
            <p className="c22">
                <span className="c0">RECITALS</span>
            </p>
            <p className="c13">
                <span className="c2">
                    WHEREAS, the Company operates an e-commerce website allowing artists to
                    showcase and sell their merchandise; and
                </span>
            </p>
            <p className="c13">
                <span className="c2">
                    WHEREAS, the Client desires to utilize the services offered by the Company
                    to promote, market, and sell their artistic creations; and
                </span>
            </p>
            <p className="c13">
                <span className="c2">
                    NOW, THEREFORE, in consideration of the mutual covenants contained herein
                    and other good and valuable consideration, the receipt and sufficiency of
                    which are hereby acknowledged, the Parties agree as follows:
                </span>
            </p>
            <p className="c22">
                <span className="c0">AGREEMENT TERMS</span>
            </p>
            <ol className="c6 lst-kix_list_1-0 start" start={1}>
                <li className="c24 li-bullet-0">
                    <span className="c0">DEFINITIONS:</span>
                    <span className="c2">
                        &nbsp;In this Agreement, the following terms shall have the meanings
                        ascribed to them below.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Artist</span>
                    <span className="c2">
                        ” means the individual or entity owning or controlling the Artist Marks
                        and/or Artist NIL, as applicable.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Artist Marks</span>
                    <span className="c2">
                        ” means any trademarks, logos, copyrights, designs and other
                        intellectual property rights owned or controlled by Client or Artist.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Artist NIL</span>
                    <span className="c2">
                        ” means Artist’s name, nickname(s), initials, image, voice, likeness
                        performance, photographic or graphic representations, statements made by
                        Artist, professional or personal biographies of Artist, or any other
                        identifying characteristics or materials that are provided by or on
                        behalf of Client and that identify Artist.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Artist Storefront</span>
                    <span className="c2">
                        ” refers to the dedicated online platform provided by Company,
                        accessible through the Company’s website, which serves as the primary
                        channel through which the Licensed Products are presented and sold to
                        the public.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Catalog</span>
                    <span className="c2">”</span>
                    <span className="c0">&nbsp;</span>
                    <span className="c2">
                        means the catalog of products made available by or on behalf of Company
                        from time to time for the development of Licensed Products, which may
                        include physical and virtual items such as e-books, online magazines,
                        crypto-currency, non-fungible tokens (NFTs) and other items.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Commercialization</span>
                    <span className="c2">
                        ” means the conduct of any and all activities directed to the
                        development, manufacturing, marketing, distribution, offer for
                        commercial sale, importation for commercial sale, and commercial sale of
                        Licensed Products, including but not limited to pre-launch, launch, and
                        post-launch marketing, promotion, and advertising; pricing, order
                        processing, invoicing, and sales; inventory management and commercial
                        distribution. “
                    </span>
                    <span className="c9 c8">Commercialize</span>
                    <span className="c2">” means to engage in Commercialization.</span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Licensed IP</span>
                    <span className="c2">
                        ” means, collectively, the Artist NIL and the Artist Marks.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Licensed Products</span>
                    <span className="c2">
                        ” means the products manufactured, sold and/or distributed by Company,
                        which products bear, include or incorporate any Licensed IP.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c2">“</span>
                    <span className="c9 c8">Net Profits</span>
                    <span className="c2">
                        ” means the gross amounts of revenue actually received by from the sales
                        of Licensed Products via the Artist Storefront, less the direct costs
                        and expenses incurred by Company in connection with the
                        Commercialization and transportation of such Licensed Products
                        (including, without limitation, costs of goods sold, taxes, import
                        duties, actual returns or credits on actual returns, shipping fees,
                        credit card fees, and chargebacks).
                    </span>
                </li>
            </ol>
            <p className="c19">
                <span className="c2">&nbsp;</span>
            </p>
            <ol className="c6 lst-kix_list_1-0" start={2}>
                <li className="c4 li-bullet-0">
                    <span className="c0">INTELLECTUAL PROPERTY RIGHTS</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">License Grant to Company:</span>
                    <span className="c2">
                        &nbsp;The Client hereby grants to Company the exclusive, worldwide,
                        sublicensable right and license during the Term (as defined below) to
                        use, reproduce, display, distribute, modify, adapt, enhance and create
                        derivatives of the Licensed IP in order to promote the Artist Storefront
                        and Commercialize the Licensed Products. &nbsp;Additionally, in
                        consideration for the marketing and promotional value being provided to
                        Client by Company hereunder, following the Term, Company shall have a
                        perpetual, royalty-free right and license to use the Licensed IP solely
                        in order to Commercialize any Licensed Products which were first made
                        available via the Artist Storefront during the Term, in the same manner
                        in which the Licensed IP was utilized during the Term by Company
                        pursuant to this Agreement. &nbsp;Without limiting the foregoing,
                        Company may exercise the rights granted in this Agreement across various
                        mediums, both digital and physical, in order to optimize the sale and
                        distribution of the Licensed Products, as determined by Company in its
                        sole discretion.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">License Grant to Client:</span>
                    <span className="c2">
                        &nbsp; The Company hereby grants to Client a limited, non-exclusive,
                        non-transferable right and license to use and display Company’s
                        trademarks, logos and other intellectual property as provided by Company
                        from time to time (collectively, the “
                    </span>
                    <span className="c9 c8">Company IP</span>
                    <span className="c2">
                        ”), solely in order to promote the Licensed Products, Artist Storefront
                        and the Company’s website, subject to Company’s prior written consent in
                        each instance. &nbsp;Company reserves all right, title and interest in
                        and to the Company IP, except as expressly granted herein. &nbsp;
                    </span>
                </li>
                <li className="c1 li-bullet-2">
                    <span className="c0">Ownership: </span>
                    <span className="c2">
                        Client reserves all right, title and interest in and to Licensed IP,
                        whether or not included or incorporated in the Licensed Products, except
                        as expressly granted under this Agreement. Company shall exclusively own
                        all right, title and interest in and to all of the Company IP and any
                        other intellectual property rights created or controlled by or on behalf
                        of Company (excluding any Licensed IP). &nbsp;
                    </span>
                </li>
            </ol>
            <p className="c23">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={3}>
                <li className="c4 li-bullet-0">
                    <span className="c0">CATALOG CHANGES</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Acknowledgment of Company Discretion:</span>
                    <span className="c2">
                        &nbsp;The Client acknowledges and agrees that the Company reserves the
                        unequivocal right, exercised at its sole discretion, to effect changes,
                        additions, or removals from the Catalog at any time. &nbsp;The Client
                        further recognizes that such modifications to the Catalog may have
                        consequential effects on the Licensed Products available for sale within
                        the Artist Storefront.{" "}
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Notification of Changes:</span>
                    <span className="c2">
                        &nbsp;While the Company endeavors to communicate any significant changes
                        to the Catalog in a timely manner, the Client acknowledges that, due to
                        the dynamic nature of e-commerce, instantaneous updates may be
                        imperative. As such, Company is not obligated to provide advance notice
                        for Catalog modifications.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={4}>
                <li className="c4 li-bullet-0">
                    <span className="c0">PRODUCT LISTING CONTROL</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Client Request and Company Authority:</span>
                    <span className="c2">
                        &nbsp;Client may submit requests to Company from time to time for
                        specific items from the Catalog to be added to the Artist Storefront as
                        Licensed Products. &nbsp;Company shall take into consideration all such
                        requests, provided, however, that Company shall have the final
                        determination with respect to the selection and inclusion of items made
                        available as Licensed Products for sale via the Artist Storefront.
                        &nbsp;The Company retains the unilateral right to assess and determine
                        the suitability of Licensed Products considering various factors
                        including, but not limited to, market demand, product availability, and
                        strategic business considerations. &nbsp;The Company, in exercising its
                        product listing control, is committed to maintaining high standards of
                        quality and consistency within the Artist Storefront.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Transparent Communication:</span>
                    <span className="c2">
                        &nbsp;Company shall use commercially reasonable efforts to communicate
                        with Client regarding the inclusion or exclusion of items from the
                        Artist Storefront, providing the Client with a clear understanding of
                        the rationale behind such decisions.{" "}
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={5}>
                <li className="c4 li-bullet-0">
                    <span className="c0">EXCLUSIVE SELLING RIGHTS</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Exclusive Rights:</span>
                    <span className="c2">
                        &nbsp;Subject to the terms and conditions of this Agreement, Company
                        shall have exclusive right to use the Licensed IP for the
                        Commercialization of products within the Catalog for the duration of the
                        Term. &nbsp;The Client affirms that, during the Term, neither Client nor
                        Artist shall engage in or authorize the sale or distribution of any such
                        products utilizing or incorporating any of the Licensed IP through any
                        other platform, third party, or distribution channel.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Covenant Against Competition:</span>
                    <span className="c2">
                        &nbsp;The Client agrees to refrain from entering into agreements or
                        arrangements with third parties that may directly or indirectly compete
                        with the exclusive selling rights granted to Company herein. Any breach
                        of this exclusivity covenant shall be considered a material breach of
                        this Agreement.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Breach and Damages:</span>
                    <span className="c2">
                        &nbsp;The Client acknowledges that any breach of the exclusivity
                        provisions herein may result in substantial harm to Company. Therefore,
                        in the event of a breach, Company shall be entitled to seek appropriate
                        legal remedies, including injunctive relief and monetary damages, as
                        determined in accordance with the terms of this Agreement and applicable
                        laws.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={6}>
                <li className="c4 li-bullet-0">
                    <span className="c0">PAYMENT</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Complimentary Service Provision:</span>
                    <span className="c2">
                        &nbsp;The Company extends its services without charge to the Client
                        contingent upon the maintenance of a minimum monthly sales volume of no
                        fewer than four (4) products.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Subscription Fee for Non-Compliance:</span>
                    <span className="c2">
                        &nbsp;In the event the Client fails to meet the aforementioned sales
                        volume requirement for any given month, the Company reserves the right
                        to impose a subscription fee of $4.99 USD. This fee shall be
                        automatically charged to the payment method on file to ensure the
                        continuous provision of Company services and the live status of the
                        Artist Storefront.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Initial Product Listing and Additional Fees</span>
                    <span className="c2">&nbsp;</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-2 start" start={1}>
                <li className="c25 li-bullet-3">
                    <span className="c2">
                        Within sixty (60) days after the Effective Date, Company will use
                        commercially reasonable efforts to provide the Client with three (
                    </span>
                    <span className="c8">4</span>
                    <span className="c2">
                        ) discrete Licensed Product listings, inclusive of all available sizes
                        and a maximum of three (
                    </span>
                    <span className="c8">3</span>
                    <span className="c2">) colors, where applicable.</span>
                </li>
                <li className="c25 li-bullet-4">
                    <span className="c2">
                        Should the Client wish to offer additional colors for a specific
                        Licensed Product, a one-time fee of $15 USD per additional color shall
                        be applicable.
                    </span>
                </li>
                <li className="c25 li-bullet-4">
                    <span className="c2">
                        Each addition of further Licensed Products to the Client’s Artist
                        Storefront will incur a one-time fee of $35 USD per new Licensed
                        Product.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1" start={4}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Sales Milestones and Free Listed Products:</span>
                    <span className="c2">&nbsp;</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-2 start" start={1}>
                <li className="c25 li-bullet-4">
                    <span className="c2">
                        The Company acknowledges the Client’s achievements through sales
                        milestones and offers the following free listed products based on
                        cumulative sales volumes:{" "}
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-3 start" start={1}>
                <li className="c11 li-bullet-5">
                    <span className="c2">
                        Greater than 150 pieces sold: up to 5 free listed products;
                    </span>
                </li>
                <li className="c11 li-bullet-6">
                    <span className="c2">
                        Greater than 300 pieces sold: up to 6 free listed products;{" "}
                    </span>
                </li>
                <li className="c11 li-bullet-5">
                    <span className="c2">
                        Greater than 500 pieces sold: up to 7 free listed products;{" "}
                    </span>
                </li>
                <li className="c11 li-bullet-7">
                    <span className="c2">
                        Greater than 750 pieces sold: up to 8 free listed products;
                    </span>
                </li>
                <li className="c11 li-bullet-6">
                    <span className="c2">
                        Greater than 1000 pieces sold: up to 9 free listed products;
                    </span>
                </li>
                <li className="c11 li-bullet-5">
                    <span className="c2">
                        Greater than 1500 pieces sold: up to 12 free listed products; and
                    </span>
                </li>
                <li className="c11 li-bullet-5">
                    <span className="c2">
                        Greater than 2000 pieces sold: up to 20 free listed products.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1" start={5}>
                <li className="c1 li-bullet-1">
                    <span className="c0">
                        Customized Catalog Items and Procurement Services:
                    </span>
                    <span className="c2">&nbsp;</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-2 start" start={1}>
                <li className="c25 li-bullet-4">
                    <span className="c2">
                        The Company, at its sole discretion, may provide the Client with
                        customized Catalog items and procurement services. The provision of
                        these services is entirely discretionary and will be subject to fees
                        determined at the time of rendering. The Client shall be informed of any
                        applicable fees in advance of the provision of such services.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1" start={6}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Non-Payment</span>
                    <span className="c2">
                        : The Client acknowledges the critical importance of maintaining a valid
                        and active payment method on file for the seamless execution of
                        transactional obligations under this Agreement. In the event of an
                        expired, canceled, deleted, or bounced payment method, the Client agrees
                        to promptly remedy the situation within a period of 30 days from the
                        occurrence of the payment method issue. &nbsp;Client’s failure to make
                        any payments to Company and/or failure to remedy any non-payment as
                        required pursuant to this Agreement shall be considered a material
                        breach. &nbsp;
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={7}>
                <li className="c4 li-bullet-0">
                    <span className="c0">COMPANY’S OBLIGATIONS</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c1 li-bullet-1">
                    <span className="c0">Hosting and Technical Maintenance:</span>
                    <span className="c2">
                        &nbsp;The Company shall use commercially reasonable efforts to provide
                        secure and reliable hosting services to ensure the continuous
                        availability and functionality of the Company website. Technical
                        maintenance, encompassing updates, bug fixes, and overall platform
                        enhancements, shall be diligently performed to uphold the integrity of
                        the online infrastructure.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Strategic Marketing Initiatives:</span>
                    <span className="c2">
                        &nbsp;The Company shall use commercially reasonable efforts to execute
                        strategic marketing initiatives aimed at promoting the Artist Storefront
                        and the Licensed Products, which initiatives may include the formulation
                        and implementation of marketing campaigns, promotional activities, and
                        other relevant strategies to enhance the visibility and desirability of
                        the Licensed Products.
                    </span>
                </li>
                <li className="c1 li-bullet-2">
                    <span className="c0">Product Procurement and Fulfillment:</span>
                    <span className="c2">
                        &nbsp;The Company assumes the responsibility for the procurement of
                        Licensed Products and shall use commercially reasonable efforts to
                        curate a diverse and attractive product Catalog. &nbsp;As between the
                        Parties, Company shall own all Licensed Products, excluding any Licensed
                        IP included or incorporated therein. &nbsp;Additionally, the Company
                        shall manage the fulfillment process, ensuring timely and accurate
                        delivery of the Licensed Products to customers.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Customer Support Services:</span>
                    <span className="c2">
                        &nbsp;The Company undertakes to provide comprehensive customer support
                        services, addressing inquiries, concerns, and issues raised by customers
                        in relation to the Licensed Products offered within the Artist
                        Storefront. This support may include pre-purchase queries, order
                        tracking, and post-purchase assistance, fostering a positive and
                        satisfactory customer experience.
                    </span>
                </li>
                <li className="c1 li-bullet-1" id="h.gjdgxs">
                    <span className="c0">Issue Resolution:</span>
                    <span className="c2">
                        &nbsp;The Company shall use commercially reasonable efforts to resolve
                        any technical issues, glitches, or challenges within Company’s control
                        that may unexpectedly prevent the Artist Storefront from being
                        accessible to consumers during the Term.
                    </span>
                </li>
                <li className="c1 li-bullet-1">
                    <span className="c0">Operational Compliance:</span>
                    <span className="c2">
                        &nbsp;In all operational aspects, the Company affirms its commitment to
                        compliance with applicable laws, regulations, and industry standards.
                        The Company pledges to conduct its operations in an ethical and lawful
                        manner, upholding the integrity of the services rendered to the Client.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={8}>
                <li className="c4 li-bullet-0">
                    <span className="c0">COMMISSION</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c7 li-bullet-8">
                    <span className="c0">Calculation and Disbursement:</span>
                    <span className="c2">
                        &nbsp;In consideration of the collaborative arrangement and the rights
                        granted herein, the Company agrees to remit to the Client a commission
                        equal to forty percent (40%) of Net Profits for each Licensed Product
                        sold via the Artist Storefront (the “
                    </span>
                    <span className="c8 c9">Commission</span>
                    <span className="c2">”). </span>
                </li>
                <li className="c7 li-bullet-9">
                    <span className="c0">Monthly Payment Schedule:</span>
                    <span className="c2">
                        &nbsp;The Client shall receive Commission payments on a monthly basis,
                        disbursed within fifteen (15) days of the conclusion of the preceding
                        month. Commission payments will be paid exclusively through transactions
                        conducted via debit card or checking account, as agreed by the Parties.
                    </span>
                </li>
                <li className="c7 li-bullet-10">
                    <span className="c0">Comprehensive Sales Reporting:</span>
                    <span className="c2">
                        &nbsp;The Company undertakes to provide the Client with a reasonably
                        detailed and comprehensive monthly commission report. This report shall
                        include sales data derived from the products listed within the Artist
                        Storefront, showing the performance and revenue generated by the
                        Licensed Products.
                    </span>
                </li>
                <li className="c7 li-bullet-11">
                    <span className="c0">Currency and Precision:</span>
                    <span className="c2">
                        &nbsp;All commission payments and financial transactions shall be
                        conducted in USD. The Company pledges to adhere to the highest standards
                        of financial precision and accuracy in the calculation and disbursement
                        of commissions, ensuring a transparent and equitable financial
                        arrangement between the Company and the Client.
                    </span>
                </li>
                <li className="c7 li-bullet-8">
                    <span className="c0">Auditable Transaction Records:</span>
                    <span className="c2">
                        &nbsp;The Company shall maintain accurate transaction records pertaining
                        to commission payments, ensuring the availability of a verifiable
                        financial trail. These records shall be made available to the Client for
                        inspection upon Client’s reasonable request, no more than one (1) time
                        per calendar year during Company’s regular business hours.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-0" start={9}>
                <li className="c4 li-bullet-12">
                    <span className="c0">ALERTS</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-13">
                    <span className="c0">Timely Notification Protocol:</span>
                    <span className="c2">
                        &nbsp;The Company recognizes the critical importance of maintaining
                        operational transparency and prompt communication with the Client. In
                        the event of any significant disruption to the Artist Storefront,
                        Company shall use commercially reasonable efforts to initiate a timely
                        notification protocol. The Company shall use commercially reasonable
                        efforts to provide the Client with reasonably detailed reports outlining
                        the nature, scope, and potential impact of the identified disruption.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Collaborative Resolution:</span>
                    <span className="c2">
                        &nbsp;In the event of a disruption, the Company and Client shall work
                        together in good faith to address and resolve the issue promptly. This
                        collaborative approach includes transparent communication, providing
                        regular updates on the status of the resolution efforts, and actively
                        engaging in joint problem-solving to mitigate any adverse effects on the
                        Artist Storefront.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-0" start={10}>
                <li className="c4 li-bullet-15">
                    <span className="c0">TERM AND TERMINATION</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-14">
                    <span className="c0">Term:</span>
                    <span className="c2">
                        &nbsp;This Agreement shall commence upon the Effective Date and shall
                        remain effective unless terminated in accordance with this Agreement
                        (the “
                    </span>
                    <span className="c9 c8">Term</span>
                    <span className="c2">”).</span>
                </li>
                <li className="c3 li-bullet-16">
                    <span className="c0">Termination for Material Breach:</span>
                    <span className="c2">
                        &nbsp;Each Party reserves the right to terminate this Agreement for
                        material breach of this Agreement by the other Party. Prior to effecting
                        termination for material breach, the non-breaching Party shall provide
                        ten (10) days{" "}
                    </span>
                    <a id="id.30j0zll" />
                    <span className="c2">
                        prior written notice of breach to the other Party and if such breach is
                        not cured within such ten (10) days, the Agreement shall be terminated
                        effective immediately.
                    </span>
                </li>
                <li className="c3 li-bullet-17">
                    <span className="c0">Termination Without Cause:</span>
                    <span className="c2">
                        &nbsp;In the absence of cause, either party may terminate this Agreement
                        without cause by providing written notice to the other party at least
                        thirty (30) prior to the intended termination date. Upon receipt of such
                        notice, the Agreement shall terminate at the end of the specified notice
                        period. The Parties agree that in the case of termination without cause
                        is initiated by the Client, Client forfeits any further commissions from
                        sales resulting from the sale of their merchandise and they shall have
                        no further claims to the royalties or other profitable arrangements from
                        the use of the merchandise being sold with their likeness from the date
                        of termination.
                    </span>
                </li>
                <li className="c3 li-bullet-18">
                    <span className="c0">Automatic Termination</span>
                    <span className="c2">
                        . This Agreement shall automatically terminate in the event that either
                        Party (i) becomes insolvent or is generally unable to pay, or fails to
                        pay, its debts as they become due, (ii) files or has filed against it, a
                        petition for voluntary or involuntary bankruptcy, (iii) makes or seeks
                        to make a general assignment for the benefit of its creditors, or (iv)
                        applies for or has appointed a receiver, trustee, custodian or similar
                        agent appointed by order of any court of competent.
                    </span>
                </li>
                <li className="c3 li-bullet-19">
                    <span className="c0">Mutual Agreement to Terminate:</span>
                    <span className="c2">
                        &nbsp;Both parties may mutually agree in writing to terminate this
                        Agreement at any time, effective upon the date specified in the mutual
                        termination agreement.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Effects of Termination:</span>
                    <span className="c2">&nbsp;Upon termination of this Agreement:</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-2 start" start={1}>
                <li className="c17 li-bullet-20">
                    <span className="c2">
                        Any outstanding financial obligations, including unpaid commissions,
                        subscription fees, or outstanding fees for additional services, shall
                        become due and payable; and
                    </span>
                </li>
                <li className="c17 li-bullet-21">
                    <span className="c2">
                        Any licenses or rights granted under this Agreement, including the
                        rights to use Licensed IP or Commercialize, shall terminate, except as
                        expressly provided otherwise in this Agreement.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1" start={7}>
                <li className="c3 li-bullet-22">
                    <span className="c0">Survival of Certain Provisions:</span>
                    <span className="c2">
                        &nbsp;Notwithstanding termination, the following provisions shall
                        survive:{" "}
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-2 start" start={1}>
                <li className="c17 li-bullet-23">
                    <span className="c2">
                        Any provisions related to the payment of fees, outstanding obligations;
                    </span>
                </li>
                <li className="c17 li-bullet-20">
                    <span className="c2">
                        The rights and obligations related to indemnification, confidentiality
                        and intellectual property; and
                    </span>
                </li>
                <li className="c17 li-bullet-24">
                    <span className="c2">
                        Any provisions explicitly stated to survive termination or which, by
                        their nature, should survive termination or expiration of this
                        Agreement.
                    </span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1" start={8}>
                <li className="c3 li-bullet-14">
                    <span className="c0">Transition Assistance:</span>
                    <span className="c2">
                        &nbsp;Upon termination, the Company shall provide reasonable transition
                        assistance to the Client, facilitating the orderly transition of
                        operations. This may include the provision of data exports, assistance
                        with the transfer of any remaining inventory, and collaborative efforts
                        to minimize disruption to customers.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={11}>
                <li className="c4 li-bullet-25">
                    <span className="c0">First Negotiation:</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-14">
                    <span className="c2">
                        If Client, Artist or their respective affiliates determine at any time
                        to, directly or via license to any third party, sell or distribute any
                        products that are the same or similar to any Licensed Products within
                        two (2) years following the termination or expiration of this Agreement,
                        then Client agrees to first negotiate (or cause Artist or the applicable
                        affiliate to first negotiate) in good faith exclusively with Company for
                        the purpose of entering into a new agreement whereby Company would be
                        granted rights to use the Licensed IP in order to Commercialize such
                        products utilizing Licensed IP on terms that are mutually acceptable to
                        the Parties (the “
                    </span>
                    <span className="c9 c8">Right of First Negotiation</span>
                    <span className="c2">
                        ”). &nbsp;The Right of First Negotiation, if any, shall last for a
                        period of sixty (60) days after the date upon which Client or Artist
                        notifies Company of its or their determination to utilize any Licensed
                        IP in connection with the sale or distribution of any products that are
                        the same or similar to any Licensed Products. &nbsp;If, upon completion
                        of such 60-day period but prior to the full execution of any new
                        agreement with Company in connection with the sale of such products,
                        Client, Artist or any of their respective affiliates is offered by any
                        third party a deal related to the foregoing on terms more favorable to
                        Client, Artist or their respective affiliate(s) than the terms
                        negotiated with Company, then Client, Artist or their respective
                        affiliate(s) shall be free to enter into such third-party agreement.
                    </span>
                </li>
            </ol>
            <p className="c23">
                <span className="c0" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={12}>
                <li className="c4 li-bullet-0">
                    <span className="c0">REPRESENTATIONS AND WARRANTIES</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-26">
                    <span className="c2">
                        Each Party hereby represents and warrants to the other Party that: (a)
                        it has the full right, power, authority and capacity to enter into this
                        Agreement and perform its obligations hereunder; and (b) when executed
                        and delivered, this Agreement shall constitute a legal, valid, and
                        binding obligation, enforceable in accordance with its terms and
                        conditions. &nbsp;Additionally, Client represents and warrants that it
                        has and shall maintain all necessary rights and authority to grant the
                        rights and licenses granted to Company hereunder, and that neither
                        Client nor Artist is not under any obligations and is not party to any
                        other agreement(s) that conflicts with the rights and licenses granted
                        hereunder.
                    </span>
                </li>
            </ol>
            <p className="c23">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={13}>
                <li className="c4 li-bullet-27">
                    <span className="c0">INDEMNIFICATION; LIMITATION</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-13">
                    <span className="c2">
                        Each Party shall defend, indemnify and hold harmless the other Party and
                        its affiliates and any of their respective officers, directors, members,
                        shareholders, employees, and agents from and against any loss, damage,
                        cost or expense (including reasonable attorneys’ fees) arising out of
                        any third-party claim related to or resulting from a breach of the
                        indemnifying Party’s representations or warranties provided for
                        hereunder.
                    </span>
                </li>
                <li className="c3 li-bullet-17">
                    <span className="c2">
                        EXCEPT WITH RESPECT TO EITHER PARTY’S FRAUD, GROSS NEGLIGENCE OR WILLFUL
                        MISCONDUCT OR WITH RESPECT TO COMPANY’S INDEMNIFICATION OBLIGATIONS, NO
                        PARTY SHALL BE LIABLE FOR ANY SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE
                        OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) REGARDLESS
                        OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT
                        LIABILITY OR OTHERWISE, SUFFERED BY THE OTHER PARTY, EVEN IF SUCH PARTY
                        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE OR SUCH
                        DAMAGES ARE REASONABLY FORESEEABLE.
                    </span>
                </li>
            </ol>
            <p className="c23">
                <span className="c0" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={14}>
                <li className="c4 li-bullet-0">
                    <span className="c0">DISPUTE RESOLUTION</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-19">
                    <span className="c0">Negotiation:</span>
                    <span className="c2">
                        &nbsp;In the event of any dispute, claim, or controversy arising out of
                        or relating to this Agreement, the Parties agree to first attempt to
                        resolve the matter amicably through good-faith negotiations. A written
                        notice of the dispute shall be provided by the Party initiating the
                        resolution process, and within fifteen (15) days, representatives from
                        both parties shall engage in negotiations with the aim of reaching a
                        mutually satisfactory resolution.
                    </span>
                </li>
                <li className="c3 li-bullet-28">
                    <span className="c0">Arbitration:</span>
                    <span className="c2">
                        &nbsp;If foregoing negotiation process does not result in a resolution,
                        the parties agree to submit the dispute to binding arbitration. The
                        arbitration shall be conducted in accordance with the rules applicable
                        in Delaware by a single arbitrator appointed in accordance with those
                        rules. The arbitrator's decision shall be final and binding, and
                        judgment may be entered upon it in any court having jurisdiction.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Exceptions to Arbitration:</span>
                    <span className="c2">
                        &nbsp;Notwithstanding the foregoing, the Company may seek injunctive or
                        other equitable relief to preserve the status quo or prevent irreparable
                        harm in any court of competent jurisdiction.
                    </span>
                </li>
                <li className="c3 li-bullet-26">
                    <span className="c0">Costs and Fees:</span>
                    <span className="c2">
                        &nbsp;Each Party shall bear its own costs and expenses associated with
                        the negotiation, mediation, and arbitration proceedings. The Parties
                        shall equally share the costs of the mediator and arbitration fees.
                    </span>
                </li>
                <li className="c3 li-bullet-17">
                    <span className="c0">Governing Law for Dispute Resolution:</span>
                    <span className="c2">
                        &nbsp;The laws of the jurisdiction governing this Agreement shall govern
                        the procedures and substantive law applicable to the dispute resolution
                        process, excluding any conflict of laws principles.
                    </span>
                </li>
                <li className="c3 li-bullet-13">
                    <span className="c0">Continued Performance:</span>
                    <span className="c2">
                        &nbsp;Notwithstanding the existence of a dispute, both parties shall
                        continue to fulfill their respective obligations under this Agreement
                        that are not subject to the dispute.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Litigation as a Last Resort:</span>
                    <span className="c2">
                        &nbsp;The parties acknowledge and agree that arbitration is the sole and
                        exclusive method for resolving disputes arising out of or relating to
                        this Agreement, and they expressly waive any right to bring or
                        participate in a class action or consolidated proceeding in any court.
                    </span>
                </li>
                <li className="c3 li-bullet-28">
                    <span className="c0">Waiver of Jury Trial:</span>
                    <span className="c2">
                        &nbsp;To the fullest extent permitted by law, the parties hereby waive
                        the right to a trial by jury in any action or proceeding arising out of
                        or relating to this Agreement.
                    </span>
                </li>
            </ol>
            <p className="c5">
                <span className="c2" />
            </p>
            <ol className="c6 lst-kix_list_1-0" start={15}>
                <li className="c4 li-bullet-25">
                    <span className="c0">MISCELLANEOUS</span>
                </li>
            </ol>
            <ol className="c6 lst-kix_list_1-1 start" start={1}>
                <li className="c3 li-bullet-22">
                    <span className="c0">Further Assurances</span>
                    <span className="c2">
                        : &nbsp;On a Party’s reasonable request, the other Party shall, at its
                        sole cost and expense, execute and deliver all such further documents
                        and instruments, and take all such further acts, reasonably necessary to
                        give full effect to this Agreement.
                    </span>
                </li>
                <li className="c3 li-bullet-28">
                    <span className="c0">Non-Disparagement</span>
                    <span className="c2">
                        : &nbsp;During and after the Term, neither Party shall, at any time
                        (including after the termination of this Agreement), make statements or
                        representations, or otherwise communicate, directly or indirectly, in
                        writing, orally, or otherwise, or take any action which may, directly or
                        indirectly, disparage the other Party, its affiliates, owners, officers,
                        employees, advisors, business or reputation; provided that, the
                        foregoing shall not preclude a Party from making truthful statements
                        that are required by applicable Law, regulation or legal process.
                    </span>
                </li>
                <li className="c3 li-bullet-17">
                    <span className="c0">Entire Agreement:</span>
                    <span className="c2">
                        &nbsp;This Agreement constitutes the entire understanding and agreement
                        between the Parties regarding the subject matter herein. It supersedes
                        all prior negotiations, discussions, and agreements, whether written or
                        oral, relating to the services provided under this Agreement.
                    </span>
                </li>
                <li className="c3 li-bullet-29">
                    <span className="c0">Amendments:</span>
                    <span className="c2">
                        &nbsp;No modification, amendment, or waiver of any provision of this
                        Agreement shall be effective unless in writing and signed by both
                        Parties. Any such modification, amendment, or waiver shall be limited to
                        the specific provision or provisions expressly addressed in the written
                        agreement.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Governing Law:</span>
                    <span className="c2">
                        &nbsp;This Agreement shall be governed by and construed in accordance
                        with the laws of Delaware, United States. Any legal actions, suits, or
                        proceedings arising out of or relating to this Agreement shall be venued
                        in the appropriate federal or state courts within the State of Delaware.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Waiver of Rights:</span>
                    <span className="c2">
                        &nbsp;The failure of either party to enforce any provision of this
                        Agreement shall not be deemed a waiver of its right to subsequently
                        enforce that provision or any other provision. Waiver of any breach or
                        violation of this Agreement shall not be considered a waiver of any
                        subsequent breach or violation.
                    </span>
                </li>
                <li className="c3 li-bullet-16">
                    <span className="c0">Assignment:</span>
                    <span className="c2">
                        &nbsp;This Agreement and the rights and obligations herein may not be
                        assigned, transferred, or delegated by either Party without the prior
                        written consent of the other Party.
                    </span>
                </li>
                <li className="c3 li-bullet-29">
                    <span className="c0">Notices:</span>
                    <span className="c2">
                        &nbsp;All notices, requests, demands, or other communications under this
                        Agreement shall be in writing and shall be deemed to have been duly
                        given if delivered personally, sent by overnight courier, or mailed by
                        certified or registered mail, postage prepaid, return receipt requested,
                        email, SMS, or other electronic messaging service to the addresses
                        specified in this Agreement or to such other address as either party may
                        designate by notice to the other Party.
                    </span>
                </li>
                <li className="c3 li-bullet-13">
                    <span className="c0">Independent Contractors:</span>
                    <span className="c2">
                        &nbsp;The relationship between the Parties is that of independent
                        contractors. Nothing in this Agreement shall be construed to create a
                        partnership, joint venture, agency, or employment relationship between
                        the Parties.
                    </span>
                </li>
                <li className="c3 li-bullet-16">
                    <span className="c0">Severability:</span>
                    <span className="c2">
                        &nbsp;If any provision of this Agreement is determined to be invalid,
                        illegal, or unenforceable, the remaining provisions shall remain in full
                        force and effect to the maximum extent permitted by law. The parties
                        shall undertake good-faith negotiations to replace the invalid or
                        unenforceable provision with a valid and enforceable provision that
                        achieves, to the extent possible, the economic, business, and other
                        purposes of the invalid or unenforceable provision.
                    </span>
                </li>
                <li className="c3 li-bullet-30">
                    <span className="c0">Successors</span>
                    <span className="c2">
                        : The rights and obligations of the Parties hereunder shall be binding
                        upon, inure to the benefit of and be enforceable against their
                        respective successors and assigns, legal representatives and heirs.
                    </span>
                </li>
                <li className="c3 li-bullet-14">
                    <span className="c0">Counterparts; Electronic Signatures</span>
                    <span className="c2">
                        . &nbsp;This Agreement may be executed in two or more counterparts, each
                        of which shall be deemed an original, but all of which together shall
                        constitute one and the same instrument. &nbsp;Counterparts may be
                        delivered via electronic mail (including pdf or any electronic signature
                        complying with the U.S. federal ESIGN Act of 2000, e.g.,
                        www.docusign.com) or other transmission method and any counterpart so
                        delivered shall be deemed to have been duly and validly delivered and be
                        valid and effective for all purposes.
                    </span>
                </li>
            </ol>
            <p className="c13 c27 c29">
                <span className="c2" />
            </p>
            <p className="c13 c27">
                <span className="c16" />
            </p>
            <p className="c13">
                <span className="c8">
                    IN WITNESS WHEREOF, the parties hereto have executed this Agreement, as of
                    the Effective Date first above written.
                </span>
            </p>
            <p className="c15">
                <span className="c0">
                    COMPANY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    CLIENT{" "}
                </span>
            </p>
            <p className="c15">
                <span className="c0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </p>
            <p className="c15">
                <span className="c8">
                    Reid
                    Lawrence&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c0">Name: {object?.legal}</span>
            </p>
            <p className="c15 c27">
                <span className="c2" />
            </p>
            <p className="c15">
                <span className="c12 c20">
                    <span className="sign">
                        Reid
                        Lawrence&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span className="c0">Sign: <span className="sign">{object?.toc?.sign}</span></span>
            </p>
            <p className="c15">
                <span className="c2">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </p>
            <p className="c15">
                <span className="c2">Chief Executive Officer (CEO)</span>
            </p>
            <p className="c15">
                <span className="c0">
                    Sickening Shop
                    LLC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Date: {moment(new Date)?.format('DD-MM-YYYY')}
                </span>
            </p>
            <p className="c14">
                <span className="c2" />
            </p>
            <div>
                <p className="c26">
                    <span className="c16" />
                </p>
            </div>
        </div>


    )


}

export default TC;
